<template>
  <BModal ref="error-modal" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc>
    <ErrorContentComponent :errors-state="errorsState" @hide="hide"></ErrorContentComponent>
  </BModal>
</template>

<script>
export default {
  props: ['errorsState'],
  components: {
    ErrorContentComponent: () => import('./ErrorContentComponent/ErrorContentComponent.vue'),
  },
  methods: {
    show() { this.$refs['error-modal'].show() },
    hide() { window.location.reload() },
  },
}
</script>

<style scoped>
</style>
